import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import PageContainer from './PageContainer';
import styles from './WelcomeDashboard.module.css';

const WelcomeDashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  console.log('WelcomeDashboard: Component rendered');
  console.log('WelcomeDashboard: User state:', user);

  const handleProceedToPayment = () => {
    console.log('Proceed to Payment clicked. User:', user);

    if (user) {
      navigate('/payment');
    } else {
      console.error('User not authenticated');
      navigate('/signin');  // Updated to new route name
    }
  };

  return (
    <PageContainer page="welcomeDashboard">
      <div className={styles.welcomeContent}>
        <h1 className={styles.title}>Welcome to the 10x Action Plan!</h1>
        <p className={styles.subtitle}>Thank you for registering. Here's what you can expect next:</p>
        <ol className={styles.stepsList}>
          <li>Complete the payment to unlock the 10x Action Plan surveys</li>
          <li>Fill out the Business Profile Survey (required)</li>
          <li>Optionally complete additional surveys for a more customized and insightful action plan</li>
          <li>Request your personalized 10x Action Plan</li>
        </ol>
        <p className={styles.readyText}>Ready to proceed?</p>
        <button onClick={handleProceedToPayment} className={styles.proceedButton}>Proceed to Payment</button>
      </div>
    </PageContainer>
  );
};

export default WelcomeDashboard;