import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function PrivateRoute({ children }) {
  const { user } = useAuth();
  
  console.log('PrivateRoute: User state:', user);

  if (!user) {
    console.log('PrivateRoute: No user, redirecting to signin');
    return <Navigate to="/signin" replace />; // Changed from "/login" to "/signin"
  }

  console.log('PrivateRoute: User authenticated, rendering children');
  return children;
}

export default PrivateRoute;