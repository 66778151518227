import React, { createContext, useState, useContext, useEffect } from 'react';
import { decodeJWT } from '../utils/jwtUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    console.log('AuthProvider: useEffect triggered');
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = decodeJWT(token);
      console.log('AuthContext: Decoded token', decodedToken);
      setUser({ id: decodedToken.userId, email: decodedToken.email });
      const storedPaymentStatus = localStorage.getItem('paymentStatus');
      console.log('AuthContext: Stored payment status', storedPaymentStatus);
      setPaymentStatus(storedPaymentStatus === 'true');
    } else {
      console.log('AuthProvider: No token found in localStorage');
    }
    setLoading(false);
  }, []);

  const login = (token, newPaymentStatus, userId) => {
    console.log('Login called with token:', token, 'payment status:', newPaymentStatus, 'and userId:', userId);
    localStorage.setItem('token', token);
    const decodedToken = decodeJWT(token);
    const newUser = { id: userId, email: decodedToken.email };
    console.log('Setting user to:', newUser);
    setUser(newUser);
    localStorage.setItem('paymentStatus', newPaymentStatus);
    setPaymentStatus(newPaymentStatus);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('paymentStatus');
    setUser(null);
    setPaymentStatus(null);
  };

  const updatePaymentStatus = (newStatus) => {
    console.log('Updating payment status to:', newStatus);
    localStorage.setItem('paymentStatus', newStatus);
    setPaymentStatus(newStatus);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, paymentStatus, setPaymentStatus, updatePaymentStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);