import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import { useSurveyContext } from '../context/SurveyContext';
import { surveys } from '../data/surveyData';
import QuestionSlider from './QuestionSlider';
import styles from './SurveyDashboard.module.css';
import API_BASE_URL from '../config';
import { Link } from 'react-router-dom';
import { useLocation} from 'react-router-dom';
import PageContainer from './PageContainer';

//<edited_85>
function SurveyDashboard() {
  const { user, paymentStatus, setPaymentStatus } = useAuth();
  const navigate = useNavigate();
  const [selectedSurveyIndex, setSelectedSurveyIndex] = useState(null);
  const [surveyStates, setSurveyStates] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [hasRequestedActionPlan, setHasRequestedActionPlan] = useState(false);
  const location = useLocation();

  const handleSignOut = () => {
    console.log('Sign out clicked');
    logout();
    navigate('/login');
  };
  
  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (user && user.id) {
        try {
          console.log('Fetching payment status for user:', user.id);
          const response = await fetch(`/api/user-payment-status/${user.id}`);
          if (!response.ok) {
            throw new Error('Failed to fetch payment status');
          }
          const data = await response.json();
          console.log('Received payment status from server:', data.paymentStatus);
          setPaymentStatus(data.paymentStatus);
          console.log('Updated payment status in AuthContext:', data.paymentStatus);
        } catch (error) {
          console.error('Error fetching payment status:', error);
        }
      }
    };
  
    // Check if we've just returned from a successful payment
    const urlParams = new URLSearchParams(location.search);
    const paymentSuccess = urlParams.get('payment_success');
  
    if (paymentSuccess === 'true' || !paymentStatus) {
      fetchPaymentStatus();
    }
  }, [user, setPaymentStatus, location, paymentStatus]);

  useEffect(() => {
    const fetchActionPlanStatus = async () => {
      if (user && user.id) {
        try {
          const response = await fetch(`/api/user-action-plan-status/${user.id}`);
          if (!response.ok) {
            throw new Error('Failed to fetch action plan status');
          }
          const data = await response.json();
          setHasRequestedActionPlan(data.hasRequestedActionPlan);
        } catch (error) {
          console.error('Error fetching action plan status:', error);
        }
      }
    };
  
    fetchActionPlanStatus();
  }, [user]);

  console.log('Current payment status in component:', paymentStatus);

  const { completedSurveys, completeSurvey, setCompletedSurveys } = useSurveyContext();
   

//<edited_81>

    useEffect(() => {
    // Load saved progress from local storage for each survey
    const savedStates = surveys.map((survey) => {
      const savedProgress = localStorage.getItem(`survey_${survey.id}_progress`);
      if (savedProgress) {
        const { answers, currentQuestionIndex } = JSON.parse(savedProgress);
        return { answers, currentQuestionIndex, errors: {} };
      }
      return { answers: {}, currentQuestionIndex: 0, errors: {} };
    });
    setSurveyStates(savedStates);
  }, []);



  const handleSurveySelect = useCallback((index) => {
    setSelectedSurveyIndex(index);
  }, []);

  const handleQuestionChange = useCallback((questionIndex) => {
    if (selectedSurveyIndex !== null) {
      setSurveyStates(prevStates => {
        const newStates = [...prevStates];
        newStates[selectedSurveyIndex] = { 
          ...newStates[selectedSurveyIndex], 
          currentQuestionIndex: questionIndex 
        };
        return newStates;
      });
    }
  }, [selectedSurveyIndex]);

  const handleInputChange = (questionId, value) => {
    setSurveyStates(prevStates => {
      const newStates = [...prevStates];
      const currentState = { ...newStates[selectedSurveyIndex] };
      if (typeof questionId === 'string' && questionId.endsWith('_other')) {
        currentState.answers = {
          ...currentState.answers,
          [questionId]: value
        };
      } else {
        currentState.answers = {
          ...currentState.answers,
          [questionId]: value
        };
      }
      currentState.errors = { ...currentState.errors, [questionId]: null };
      newStates[selectedSurveyIndex] = currentState;
      return newStates;
    });
    saveProgress();
  };

  const updateTestUserPayment = async () => {
    try {
      const response = await fetch(`/api/update-test-user-payment`, {
        method: 'POST',
      });
      const data = await response.json();
      alert(data.message);
    } catch (error) {
      console.error('Error updating test user payment status:', error);
      alert('Failed to update test user payment status');
    }
  };

  const handleActionPlanRequest = async () => {
    try {
      const userId = user.id;
      
      const requestData = { 
        surveyResponses: surveyStates,
        userId: userId
      };
      
      console.log('Sending request data:', JSON.stringify(requestData, null, 2));

      const response = await fetch(`/api/request-action-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      const responseData = await response.json();
      console.log('Response status:', response.status);
      console.log('Response data:', responseData);
  
      if (!response.ok) {
        throw new Error(`Failed to submit Action Plan request: ${responseData.message || 'Unknown error'}`);
      }
  
      alert("Your 10x Action Plan request has been submitted. You will receive your personalized plan via email within 24-48 hours.");
    } catch (error) {
      console.error('Detailed error requesting Action Plan:', error);
      alert(`An error occurred while requesting the Action Plan: ${error.message}`);
    }
  };


const resetSurveyState = () => {
  setSurveyStates(surveys.map(() => ({
    answers: {},
    currentQuestionIndex: 0,
    errors: {}
  })));
  localStorage.removeItem('surveyStates');
  setSelectedSurveyIndex(null);
  setShowSummary(false);
  // Clear individual survey progress
  surveys.forEach(survey => {
    localStorage.removeItem(`survey_${survey.id}_progress`);
  });
  // Reset completed surveys in context
  setCompletedSurveys([]);
};

  const saveProgress = () => {
    if (selectedSurveyIndex !== null) {
      const currentSurvey = surveys[selectedSurveyIndex];
      const currentState = surveyStates[selectedSurveyIndex];
      localStorage.setItem(`survey_${currentSurvey.id}_progress`, JSON.stringify({
        answers: currentState.answers,
        currentQuestionIndex: currentState.currentQuestionIndex
      }));
    }
  };

  const handleSaveProgress = () => {
    saveProgress();
    alert('Your progress has been saved. You can return to this survey later to complete it.');
  };

  const validateQuestion = (question) => {
    if (selectedSurveyIndex !== null) {
      const currentState = surveyStates[selectedSurveyIndex];
      if (question.required) {
        if (!currentState.answers[question.id] || 
            (typeof currentState.answers[question.id] === 'string' && currentState.answers[question.id].trim() === '') ||
            (Array.isArray(currentState.answers[question.id]) && currentState.answers[question.id].length === 0)) {
          setSurveyStates(prevStates => {
            const newStates = [...prevStates];
            const updatedState = { ...newStates[selectedSurveyIndex] };
            updatedState.errors = { ...updatedState.errors, [question.id]: 'This field is required' };
            newStates[selectedSurveyIndex] = updatedState;
            return newStates;
          });
          return false;
        }
      }
    }
    return true;
  };

  const handleNext = () => {
    if (selectedSurveyIndex !== null) {
      const currentSurvey = surveys[selectedSurveyIndex];
      const currentState = surveyStates[selectedSurveyIndex];
      const currentQuestion = currentSurvey.questions[currentState.currentQuestionIndex];
      if (validateQuestion(currentQuestion)) {
        if (currentState.currentQuestionIndex === currentSurvey.questions.length - 1) {
          // Instead of showing summary, mark the survey as completed
          completeSurvey(currentSurvey.id);
          setSelectedSurveyIndex(null);
        } else {
          setSurveyStates(prevStates => {
            const newStates = [...prevStates];
            const updatedState = { ...newStates[selectedSurveyIndex] };
            updatedState.currentQuestionIndex += 1;
            newStates[selectedSurveyIndex] = updatedState;
            return newStates;
          });
        }
        saveProgress();
      }
    }
  };

  const handlePrevious = () => {
    if (selectedSurveyIndex !== null) {
      setSurveyStates(prevStates => {
        const newStates = [...prevStates];
        const updatedState = { ...newStates[selectedSurveyIndex] };
        updatedState.currentQuestionIndex -= 1;
        newStates[selectedSurveyIndex] = updatedState;
        return newStates;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedSurveyIndex !== null) {
      const currentSurvey = surveys[selectedSurveyIndex];
      const isValid = currentSurvey.questions.every(validateQuestion);
      if (isValid) {
        completeSurvey(currentSurvey.id);
        localStorage.removeItem(`survey_${currentSurvey.id}_progress`);
        alert(`${currentSurvey.name} completed successfully!`);
        setSelectedSurveyIndex(null);
      } else {
        alert('Please fill in all required fields before submitting.');
      }
    }
  };

  if (paymentStatus === null) {
    return <div>Loading...</div>;
  }

  const renderQuestion = (question) => {
    if (selectedSurveyIndex === null) return null;
    const { answers } = surveyStates[selectedSurveyIndex];
    const currentQuestionIndex = surveyStates[selectedSurveyIndex].currentQuestionIndex;
  
    const renderQuestionContent = () => {
      switch (question.type) {
        case 'text':
        case 'number':
          return (
            <input
              type={question.type}
              value={answers[question.id] || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              className={styles.input}
            />
          );
        case 'textarea':
          return (
            <textarea
              value={answers[question.id] || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              className={styles.textarea}
            />
          );
        case 'radio':
          return (
            <div>
              {question.options.map(option => (
                <label key={option} className={styles.radio}>
                  <input
                    type="radio"
                    name={`question_${question.id}`}
                    value={option}
                    checked={answers[question.id] === option}
                    onChange={(e) => handleInputChange(question.id, e.target.value)}
                  />
                  {option}
                </label>
              ))}
              {question.allowOther && (
                <div className={styles.otherOption}>
                  <label className={styles.radio}>
                    <input
                      type="radio"
                      name={`question_${question.id}`}
                      value="Other"
                      checked={answers[question.id] === 'Other'}
                      onChange={(e) => handleInputChange(question.id, 'Other')}
                    />
                    Other:
                  </label>
                  <input
                    type="text"
                    value={answers[question.id] === 'Other' ? (answers[question.id + '_other'] || '') : ''}
                    onChange={(e) => handleInputChange(question.id + '_other', e.target.value)}
                    disabled={answers[question.id] !== 'Other'}
                    className={styles.otherInput}
                  />
                </div>
              )}
            </div>
          );
        case 'checkbox':
          return (
            <div>
              {question.options.map(option => (
                <label key={option} className={styles.checkbox}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={Array.isArray(answers[question.id]) && answers[question.id].includes(option)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...(answers[question.id] || []), option]
                        : (answers[question.id] || []).filter(item => item !== option);
                      handleInputChange(question.id, newValue);
                    }}
                  />
                  {option}
                </label>
              ))}
              {question.allowOther && (
                <div className={styles.otherOption}>
                  <label className={styles.checkbox}>
                    <input
                      type="checkbox"
                      value="Other"
                      checked={Array.isArray(answers[question.id]) && answers[question.id].includes('Other')}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? [...(answers[question.id] || []), 'Other']
                          : (answers[question.id] || []).filter(item => item !== 'Other');
                        handleInputChange(question.id, newValue);
                      }}
                    />
                    Other:
                  </label>
                  <input
                    type="text"
                    value={answers[question.id + '_other'] || ''}
                    onChange={(e) => handleInputChange(question.id + '_other', e.target.value)}
                    disabled={!Array.isArray(answers[question.id]) || !answers[question.id].includes('Other')}
                    className={styles.otherInput}
                  />
                </div>
              )}
            </div>
          );
        case 'scale':
          return (
            <div className={styles.scaleContainer}>
              {Array.from({ length: question.max - question.min + 1 }, (_, i) => i + question.min).map(value => (
                <label key={value} className={styles.scaleOption}>
                  <input
                    type="radio"
                    name={`question_${question.id}`}
                    value={value}
                    checked={parseInt(answers[question.id]) === value}
                    onChange={(e) => handleInputChange(question.id, e.target.value)}
                  />
                  <span>{value}</span>
                  <span className={styles.scaleLabel}>{question.labels[value]}</span>
                </label>
              ))}
            </div>
          );
        default:
          return null;
      }
    };
  
    return (
      <div className={styles.questionContainer}>
        <label className={styles.questionLabel}>
          <span className={styles.questionNumber}>{currentQuestionIndex + 1}.</span>
          {question.text}
        </label>
        {renderQuestionContent()}
      </div>
    );
  };

  const overallProgress = completedSurveys.length / surveys.length;
  const canRequestActionPlan = completedSurveys.includes(1); // Assuming survey with id 1 is the Business Profile

  //return statement begins here? 

return (
  <PageContainer page="surveyDashboard">
    <div className={styles.surveyDashboardContent}>
      <Link to="/login" className={styles.signOutLink} onClick={handleSignOut}>
      Sign Out
    </Link>
    <div className={styles.versionInfo}>
      V1.0.6X Alpha: Current version does not represent the quality of the final product.
    </div>
      {console.log('Rendering with payment status:', paymentStatus)}
      {paymentStatus === false ? (
  <div className={styles.paymentRequired}>
    <h2>Payment Required</h2>
    <p>Please complete the payment to access the surveys.</p>
    <button onClick={() => navigate('/payment')} className={styles.paymentButton}>
      Proceed to Payment
    </button>
  </div>
) : (
        // Existing survey dashboard content
        <>
      <h1>10x Action Plan Surveys</h1>
      <div className={styles.actionPlanSection}>
      {hasRequestedActionPlan ? (
        <p className={styles.actionPlanMessage}>
          You have already requested the 10x Action Plan. Please contact jay@10xsolutions.ai with any questions, concerns, to make any changes, or to check the status of your Action Plan.
        </p>
      ) : (
        <button 
          className={styles.actionPlanButton} 
          disabled={!canRequestActionPlan}
          onClick={handleActionPlanRequest}
        >
          Request 10x Action Plan
        </button>
      )}
    </div>
      <div className={styles.overallProgress}>
        <div className={styles.progressBar}>
          <div 
            className={styles.progressFill} 
            style={{ width: `${overallProgress * 100}%` }}
          ></div>
        </div>
        <p>{completedSurveys.length} of {surveys.length} surveys completed</p>
      </div>
      <div className={styles.surveyList}>
        {surveys.map((survey, index) => (
          <button
            key={survey.id}
            onClick={() => handleSurveySelect(index)}
            className={`${styles.surveyButton} ${completedSurveys.includes(survey.id) ? styles.completed : ''}`}
          >
            {survey.name}
            {completedSurveys.includes(survey.id) && ' ✓'}
          </button>
        ))}
      </div>

      {selectedSurveyIndex !== null && !showSummary && (
  <div className={styles.surveyForm}>
    <h2>{surveys[selectedSurveyIndex].name}</h2>
    <QuestionSlider
      totalQuestions={surveys[selectedSurveyIndex].questions.length}
      currentQuestion={surveyStates[selectedSurveyIndex].currentQuestionIndex}
      onQuestionChange={handleQuestionChange}
    />
    <form onSubmit={handleSubmit}>
    <div className={styles.questionContainer}>
  {renderQuestion(surveys[selectedSurveyIndex].questions[surveyStates[selectedSurveyIndex].currentQuestionIndex])}
  {surveyStates[selectedSurveyIndex].errors[surveys[selectedSurveyIndex].questions[surveyStates[selectedSurveyIndex].currentQuestionIndex].id] && (
    <p className={styles.error}>
      {surveyStates[selectedSurveyIndex].errors[surveys[selectedSurveyIndex].questions[surveyStates[selectedSurveyIndex].currentQuestionIndex].id]}
    </p>
  )}
</div>
      <div className={styles.navigationButtons}>
  <button type="button" onClick={handlePrevious} disabled={surveyStates[selectedSurveyIndex].currentQuestionIndex === 0}>Previous</button>
  <button type="button" onClick={handleSaveProgress}>Save Progress</button>
  {surveyStates[selectedSurveyIndex].currentQuestionIndex < surveys[selectedSurveyIndex].questions.length - 1 ? (
    <button type="button" onClick={handleNext}>Next</button>
  ) : (
    <button type="submit">Review Answers</button>
  )}
</div>
<button onClick={resetSurveyState} className={styles.resetButton}>
  Reset All Surveys
</button>



    </form>
  </div>
)}
      {showSummary && (
        <SurveySummary 
          survey={surveys[selectedSurveyIndex]} 
          answers={surveyStates[selectedSurveyIndex].answers}
          onEdit={() => setShowSummary(false)}
          onSubmit={handleSubmit}
        />
      )}
</>
    )}
    <footer className={styles.footer}>
      <ul>
        <li>Your 10x Action Plan can be requested once you have completed the Business Profile Survey. Other surveys will enhance your results but are optional.</li>
        <li>During the Alpha-test phase, email automation has been disabled. We will be manually monitoring for completed requests and will email with a confirmation within one business day.</li>
        <li>Confidentiality is a promise. We will never sell your information and any identifying information is redacted prior to AI analysis.</li>
        <li>We expect problems to occur. Email us at jay@10xsolutions.ai with any problems or concerns.</li>
      </ul>
    </footer>
  </div>
  </PageContainer>
);
}

export default SurveyDashboard;