import React from 'react';
import styles from './PageContainer.module.css';

const PageContainer = ({ children, page }) => {
  return (
    <div className={`${styles.pageContainer} ${styles[page]}`}>
      <div className={styles.background}></div>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default PageContainer;