import React, { createContext, useState, useContext, useEffect } from 'react';
import { surveys as initialSurveys } from '../data/surveyData';

const SurveyContext = createContext();

export const useSurveyContext = () => useContext(SurveyContext);

export const SurveyProvider = ({ children }) => {
  const [completedSurveys, setCompletedSurveys] = useState(() => {
    const saved = localStorage.getItem('completedSurveys');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    localStorage.setItem('completedSurveys', JSON.stringify(completedSurveys));
  }, [completedSurveys]);

  const completeSurvey = (surveyId) => {
    setCompletedSurveys(prev => {
      if (!prev.includes(surveyId)) {
        return [...prev, surveyId];
      }
      return prev;
    });
  };

  const isBusinessProfileComplete = completedSurveys.includes(1);

  return (
    <SurveyContext.Provider value={{ 
      surveys: initialSurveys, 
      completeSurvey, 
      completedSurveys, 
      setCompletedSurveys, 
      isBusinessProfileComplete 
    }}>
      {children}
    </SurveyContext.Provider>
  );
};