import React from 'react';
import { Link } from 'react-router-dom';
import PageContainer from './PageContainer';
import styles from './LandingPage.module.css';

const LandingPage = () => {
  return (
    <PageContainer page="landingPage">
      <div className={styles.content}>
        <h1 className={styles.title}>10x Action Plan</h1>
        <p className={styles.subtitle}>Accelerate your business growth</p>
        <div className={styles.buttonContainer}>
          <Link to="/signin" className={styles.button}>
            Sign In
          </Link>
          <Link to="/signup" className={styles.button}>
            Sign Up
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};

export default LandingPage;