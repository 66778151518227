// src/data/surveyData.js

export const surveys = [
    {
      id: 1,
      name: "Business Profile",
      description: "Thank you for choosing our 10x Growth Plan service. Please complete this survey to help us understand your business better. This should take approximately 30 minutes to complete.",
      questions: [
        {
          id: 1,
          text: "  What is your company's name?",
          type: "text",
          required: true
        },
        {
          id: 2,
          text: "  In which year was your company founded?",
          type: "number",
          required: true
        },
        {
          id: 3,
          text: "  What is your primary industry?",
          type: "radio",
          options: ["Technology   ", "Retail   ", "Manufacturing   ", "Services   ", "Healthcare   ", "Finance   "],
          required: true,
          allowOther: true
        },
        {
          id: 4,
          text: "  How many employees does your company have?",
          type: "radio",
          options: ["1-10   ", "11-50   ", "51-200   ", "201-500   ", "501-1000   ", "1000+   "],
          required: true
        },
        {
          id: 5,
          text: "  What was your company's approximate annual revenue in the last fiscal year?",
          type: "radio",
          options: [
            "Less than $100,000   ",
            "$100,000 - $500,000   ",
            "$500,001 - $1 million   ",
            "$1 million - $5 million   ",
            "$5 million - $20 million   ",
            "$20 million - $100 million   ",
            "Over $100 million   "
          ],
          required: true
        },
        {
          id: 6,
          text: "  How would you describe your company's current growth rate?",
          type: "radio",
          options: [
            "Declining   ",
            "Stable   ",
            "Slow growth (1-10% annually)   ",
            "Moderate growth (11-25% annually)   ",
            "Rapid growth (26-50% annually)   ",
            "Hypergrowth (over 50% annually)   "
          ],
          required: true
        },
        {
          id: 7,
          text: "  What is your primary business model?",
          type: "radio",
          options: [
            "B2B (Business to Business)   ",
            "B2C (Business to Consumer)   ",
            "B2G (Business to Government)   ",
            "Marketplace   ",
            "Subscription   ",
            "Freemium   "
          ],
          required: true,
          allowOther: true
        },
        {
          id: 8,
          text: "  In which geographic markets does your company primarily operate?",
          type: "checkbox",
          options: ["Local   ", "Regional   ", "National   ", "International   "],
          required: true
        },
        {
          id: 9,
          text: "  How would you rate your company's market position compared to competitors?",
          type: "scale",
          min: 1,
          max: 5,
          labels: {
            1: "  Significantly behind   ",
            2: "  Slightly behind   ",
            3: "  On par   ",
            4: "  Slightly ahead   ",
            5: "  Market leader   "
          },
          required: true
        },
        {
          id: 10,
          text: "  What are your company's top 3 competitive advantages?",
          type: "textarea",
          required: true
        },
        {
          id: 11,
          text: "  What are the 3 biggest challenges your company is currently facing?",
          type: "textarea",
          required: true
        },
        {
          id: 12,
          text: "  How would you rate your company's current financial health?",
          type: "scale",
          min: 1,
          max: 5,
          labels: {
            1: "  Poor   ",
            2: "  Fair   ",
            3: "  Good   ",
            4: "  Very good   ",
            5: "  Excellent   "
          },
          required: true
        },
        {
          id: 13,
          text: "  What is your company's primary goal for the next 12 months?",
          type: "radio",
          options: [
            "Increase revenue   ",
            "Improve profitability   ",
            "Expand to new markets   ",
            "Launch new products/services   ",
            "Increase market share   ",
            "Operational efficiency   "
          ],
          required: true,
          allowOther: true
        },
        {
          id: 14,
          text: "  How would you rate your company's current use of technology?",
          type: "scale",
          min: 1,
          max: 5,
          labels: {
            1: "  Very limited   ",
            2: "  Basic   ",
            3: "  Moderate   ",
            4: "  Advanced   ",
            5: "  Cutting-edge   "
          },
          required: true
        },
        {
          id: 15,
          text: "  What is your company's approach to innovation?",
          type: "radio",
          options: [
            "Reactive (respond to market changes)   ",
            "Proactive (anticipate and lead market changes)   ",
            "Collaborative (partner with others to innovate)   ",
            "Open innovation (source ideas from outside the company)   ",
            "Internal R&D focused   "
          ],
          required: true
        },
        {
          id: 16,
          text: "  How would you describe your company's organizational structure?",
          type: "radio",
          options: [
            "Hierarchical   ",
            "Flat   ",
            "Matrix   ",
            "Team-based   ",
            "Network   "
          ],
          required: true,
          allowOther: true
        },
        {
          id: 17,
          text: "  What is your customer acquisition strategy?",
          type: "checkbox",
          options: [
            "Digital marketing   ",
            "Traditional advertising   ",
            "Content marketing   ",
            "Referral programs   ",
            "Sales team   ",
            "Partnerships   "
          ],
          required: true,
          allowOther: true
        },
        {
          id: 18,
          text: "  How would you rate your company's customer retention?",
          type: "scale",
          min: 1,
          max: 5,
          labels: {
            1: "Poor   ",
            2: "Fair   ",
            3: "Good   ",
            4: "Very good   ",
            5: "Excellent   "
          },
          required: true
        },
        {
          id: 19,
          text: "  What percentage of your revenue is typically reinvested in growth initiatives?",
          type: "radio",
          options: [
            "Less than 5%   ",
            "5-10%   ",
            "11-20%   ",
            "21-30%   ",
            "More than 30%   "
          ],
          required: true
        },
        {
          id: 20,
          text: "  How would you rate your company's adaptability to market changes?",
          type: "scale",
          min: 1,
          max: 5,
          labels: {
            1: "Very rigid   ",
            2: "Somewhat inflexible   ",
            3: "Moderately adaptable   ",
            4: "Highly adaptable   ",
            5: "Extremely agile   "
          },
          required: true
        },
        {
          id: 21,
          text: "  Is there any additional information about your company that you believe is important for us to know in creating your 10x Growth Plan?",
          type: "textarea",
          required: false
        }
      ]
    },
    {
      id: 2,
      name: "Organizational Alignment",
      description: "This questionnaire assesses organizational health based on key principles outlined by Patrick Lencioni.",
      questions: [
        {
          id: 1,
          text: "  How well does the leadership team function in terms of collective responsibility for achieving common organizational objectives?",
          type: "radio",
          options: ["Not Effectively   ", "Somewhat Effectively   ", "Very Effectively   ", "Exceptionally Well   "],
          significance: "Assesses the unity and effectiveness of the leadership team, a core component of organizational health as cohesive leadership is foundational for setting clear objectives and leading by example."
        },
        {
          id: 2,
          text: "  How consistently does your team achieve its designated goals?",
          type: "radio",
          options: ["Never   ", "Rarely   ", "Sometimes   ", "Often   ", "Always   "],
          significance: "Measures the reliability of team output, reflecting the alignment of team efforts with organizational goals, a key indicator of effective communication and coordination."
        },
        {
          id: 3,
          text: "  How aligned are your documentation and actual practices throughout the organization?",
          type: "radio",
          options: ["Not Aligned   ", "Slightly Aligned   ", "Moderately Aligned   ", "Highly Aligned   ", "Fully Aligned   "],
          significance: "Evaluates the integrity and consistency of operational practices with stated policies, crucial for maintaining transparency and trust within the organization."
        },
        {
          id: 4,
          text: "  How effective is communication across different levels of the organization?",
          type: "radio",
          options: ["Very Ineffective   ", "Ineffective   ", "Moderately Effective   ", "Effective   ", "Very Effective   "],
          significance: "Directly correlates to organizational health by assessing how well information flows across hierarchical boundaries, facilitating timely decision-making and engagement."
        },
        {
          id: 5,
          text: "  How engaged do you feel employees are with their work and the organization?",
          type: "radio",
          options: ["Very Disengaged   ", "Disengaged   ", "Neutral   ", "Engaged   ", "Very Engaged   "],
          significance: "Assesses employee motivation and connection to the organization's goals, which are critical for sustaining high performance and reducing turnover."
        },
        {
          id: 6,
          text: "  How effectively does your organization adapt to changes in the market and industry?",
          type: "radio",
          options: ["Not Effectively   ", "Rarely Effectively   ", "Sometimes Effectively   ", "Often Effectively   ", "Always Effectively   "],
          significance: "Checks the organization's responsiveness to external changes, a vital trait for long-term survival and competitiveness."
        },
        {
          id: 7,
          text: "  How effectively does your organization handle conflicts within the team?",
          type: "radio",
          options: ["Very Ineffectively   ", "Ineffectively   ", "Moderately Effectively   ", "Effectively   ", "Very Effectively   "],
          significance: "Important for assessing the culture of conflict resolution, which impacts team cohesion and the ability to maintain a healthy work environment."
        },
        {
          id: 8,
          text: "  How well are your organization's strategic goals aligned with the day-to-day operations?",
          type: "radio",
          options: ["Not Aligned   ", "Slightly Aligned   ", "Moderately Aligned   ", "Highly Aligned   ", "Fully Aligned   "],
          significance: "Crucial for determining if there is a clear line of sight from strategic planning to operational tasks, ensuring that daily activities contribute to larger objectives."
        },
        {
          id: 9,
          text: "  How would you rate the strength and clarity of your organization's culture?",
          type: "radio",
          options: ["Very Weak/Unclear   ", "Weak/Somewhat Unclear   ", "Neutral   ", "Strong/Somewhat Clear   ", "Very Strong/Clear   "],
          significance: "Gauges the robustness of the organizational culture, which influences employee satisfaction and the organization’s identity and values."
        },
      ]
    },
    {
      id: 3,
      name: "Strategic Stamina",
      description: "This questionnaire is designed to assess the stamina and future outlook of a company based on key factors such as cash on-hand, operational effectiveness, strategic alignment, change management, budgeting, and forecasting. Each question targets specific aspects critical to achieving long-term sustainability and growth.",
      questions: [
        {
          id: 1,
          text: "How would you rate the company's current cash reserves and financial position?",
          type: "radio",
          options: ["Very Poor   ", "Poor   ", "Adequate   ", "Strong   ", "Excellent   "],
          significance: "Evaluates the company's financial stability and liquidity, which are essential for weathering economic downturns and funding growth initiatives."
        },
        {
          id: 2,
          text: "How effectively does the company manage and optimize its operational processes?",
          type: "radio",
          options: ["Very Ineffectively   ", "Ineffectively   ", "Moderately Effectively   ", "Effectively   ", "Very Effectively   "],
          significance: "Assesses the efficiency and effectiveness of the company's day-to-day operations, which directly impact profitability and competitiveness."
        },
        {
          id: 3,
          text: "How well-aligned are the company's strategic goals with its operational activities?",
          type: "radio",
          options: ["Not Aligned   ", "Slightly Aligned   ", "Moderately Aligned   ", "Highly Aligned   ", "Fully Aligned   "],
          significance: "Evaluates the alignment between the company's long-term vision and its current actions, ensuring that resources are being allocated effectively toward achieving strategic objectives."
        },
        {
          id: 4,
          text: "How effectively does the company adapt to changes in the market and industry?",
          type: "radio",
          options: ["Very Ineffectively   ", "Ineffectively   ", "Moderately Effectively   ", "Effectively   ", "Very Effectively   "],
          significance: "Assesses the company's agility and ability to respond to external changes, which is crucial for maintaining a competitive edge and long-term relevance."
        },
        {
          id: 5,
          text: "How robust are the company's budgeting and forecasting processes?",
          type: "radio",
          options: ["Very Poor   ", "Poor   ", "Adequate   ", "Strong   ", "Excellent   "],
          significance: "Evaluates the accuracy and reliability of the company's financial projections, which are essential for effective resource allocation and strategic planning."
        },
        {
          id: 6,
          text: "How effectively does the company manage and implement organizational changes?",
          type: "radio",
          options: ["Very Ineffectively   ", "Ineffectively   ", "Moderately Effectively   ", "Effectively   ", "Very Effectively   "],
          significance: "Assesses the company's ability to navigate and adapt to internal changes, such as restructuring, new leadership, or process improvements, which can impact overall stability and performance."
        },
        {
          id: 7,
          text: "How would you rate the company's commitment to continuous improvement and innovation?",
          type: "radio",
          options: ["Very Poor   ", "Poor   ", "Adequate   ", "Strong   ", "Excellent   "],
          significance: "Evaluates the company's focus on enhancing its products, services, and processes, which is essential for maintaining a competitive advantage and driving long-term growth."
        },
        {
          id: 8,
          text: "How effectively does the company manage and mitigate risks to its operations?",
          type: "radio",
          options: ["Very Ineffectively   ", "Ineffectively   ", "Moderately Effectively   ", "Effectively   ", "Very Effectively   "],
          significance: "Assesses the company's ability to identify and address potential threats to its business, such as regulatory changes, supply chain disruptions, or cyber risks, which can impact its long-term viability."
        },
        {
          id: 9,
          text: "How strong is the company's brand reputation and customer loyalty?",
          type: "radio",
          options: ["Very Weak   ", "Weak    ", "Moderate   ", "Strong   ", "Very Strong   "],
          significance: "Evaluates the strength of the company's brand and customer relationships, which can provide a competitive advantage and support long-term growth and profitability."
        }]
    },
    {
      id: 4,
      name: "AI Preparedness",
      description: "This questionnaire is designed to evaluate your company's engagement with AI technology and readiness for future advancements. Each question is aimed at understanding how AI can integrate into your business to improve operations, customer interactions, and overall efficiency.",
      questions: [
        {
          id: 1,
          text: "  Describe your core business activities and the industry you operate in.",
          type: "text",
          significance: "Helps contextualize your company's operations and industry specifics, setting the stage for tailored AI applications."
        },
        {
          id: 2,
          text: "  What key technologies does your business currently employ?",
          type: "text",
          significance: "Identifies the technological foundation already in place, which AI implementations can build upon or enhance."
        },
        {
          id: 3,
          text: "  Rate your familiarity with AI's potential in your industry.",
          type: "text",
          options: ["Not Familiar   ", "Somewhat Familiar   ", "Moderately Familiar   ", "Very Familiar   ", "Expert   "],
          significance: "Assesses awareness and understanding of AI capabilities specific to your industry, essential for strategic integration."
        },
        {
          id: 4,
          text: "  What operational challenges are you facing where AI could have an impact?",
          type: "text",
          significance: "Highlights areas of potential AI application to address efficiency, accuracy, or scalability issues."
        },
        {
          id: 5,
          text: "  How does your business leverage data in decision-making?",
          type: "text",
          significance: "Evaluates the role of data analytics in your business operations, crucial for implementing AI-driven insights."
        },
        {
          id: 6,
          text: "  Are there customer-facing areas where AI could be beneficial?",
          type: "text",
          significance: "Identifies opportunities for AI to enhance customer service, personalization, or engagement."
        },
        {
          id: 7,
          text: "  Are the workflows within your organization up to date and well executed?",
          type: "radio",
          options: ["Not at all   ", "Rarely   ", "Sometimes   ", "Mostly   ", "Always   "],
          significance: "Measures the effectiveness and maintenance of current processes, indicating the readiness for AI-facilitated improvements."
        },
        {
          id: 8,
          text: "  How do you envision AI fitting into your business's future?",
          type: "text",
          significance: "Explores strategic goals for AI, assessing long-term planning and innovation potential."
        },
        {
          id: 9,
          text: "  How ready is your business to integrate AI solutions?",
          type: "radio",
          options: ["Not Ready   ", "Slightly Ready   ", "Moderately Ready   ", "Very Ready   ", "Fully Prepared   "],
          significance: "Determines the current capability and infrastructure to adopt AI, critical for successful deployment."
        }
      ]
    }
  ];