// File: 10X_ACTION_PLAN/src/components/QuestionSlider.js

import React from 'react';
import styles from './QuestionSlider.module.css';

function QuestionSlider({ totalQuestions, currentQuestion, onQuestionChange }) {
  const handleSliderChange = (event) => {
    onQuestionChange(Number(event.target.value));
  };

  return (
    <div className={styles.sliderContainer}>
      <input
        type="range"
        min="0"
        max={totalQuestions - 1}
        value={currentQuestion}
        onChange={handleSliderChange}
        className={styles.slider}
      />
    </div>
  );
}

export default QuestionSlider;