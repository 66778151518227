import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import { AuthProvider } from './context/AuthContext';
import { SurveyProvider } from './context/SurveyContext';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import SurveyDashboard from './components/SurveyDashboard';
import StripeCheckoutButton from './components/StripeCheckoutButton';
import PrivateRoute from './components/PrivateRoute';
import WelcomeDashboard from './components/WelcomeDashboard';

function App() {
  return (
    <AuthProvider>
      <SurveyProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route 
              path="/payment" 
              element={
                <PrivateRoute>
                  <StripeCheckoutButton />
                </PrivateRoute>
              } 
            />
            <Route path="/payment-success" element={<Navigate to="/dashboard" replace />} />
            <Route path="/payment-cancel" element={<Navigate to="/payment" replace />} />
            <Route path="/welcome" element={<WelcomeDashboard />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <SurveyDashboard />
                </PrivateRoute>
              }
            
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </SurveyProvider>
    </AuthProvider>
  );
}

export default App;