import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import PageContainer from './PageContainer';
import styles from './SignIn.module.css';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await fetch('/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      
      if (response.ok) {
        console.log('Login successful, received data:', data);
        if (data.paymentStatus === undefined) {
          console.error('Payment status not received from server');
          setError('An error occurred. Please try again.');
          return;
        }
        login(data.token, data.paymentStatus, data.userId);
        navigate('/dashboard');
      } else {
        setError(data.msg || 'Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      console.error('Login error:', error);
    }
  };

  return (
    <PageContainer page="signInPage">
      <div className={styles.signInContainer}>
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              autoComplete="username"
              className={styles.input}
            />
          </div>
          <div>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              autoComplete="current-password"
              className={styles.input}
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <button type="submit" className={styles.button}>Sign In</button>
        </form>
        <p className={styles.signUpLink}>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </PageContainer>
  );
}

export default SignIn;