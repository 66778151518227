import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import PageContainer from './PageContainer';
import styles from './StripeCheckoutButton.module.css';

const stripePromise = loadStripe('pk_live_51P1FAFF5araui2RqzwNIFClBxb3U934wBQt9WK771FIrbPfqCCk4dk5q46w47qwL359PPwpJVrAgdUfVjagx8GHw000fwWIYi5');

const StripeCheckoutButton = () => {
    const { user, paymentStatus } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);

    console.log('StripeCheckoutButton: Component rendered');
    console.log('StripeCheckoutButton: User state:', user);
    console.log('StripeCheckoutButton: Payment status:', paymentStatus);

    useEffect(() => {
        console.log('StripeCheckoutButton: useEffect triggered');
        console.log('Current location:', location.pathname);
        if (location.pathname === '/payment' && user) {
            console.log('Conditions met for automatic checkout');
            initiateCheckout();
        }
    }, [location, user]);

    const initiateCheckout = async () => {
        console.log('initiateCheckout called. User:', user);
        if (!user) {
            console.error("User information is not available. Redirecting to signin.");
            navigate('/signin');
            return;
        }

        try {
            const stripe = await stripePromise;
            console.log('Creating checkout session for user ID:', user.id);
            console.log('Fetch URL:', '/create-checkout-session');
            const response = await fetch(`/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id }),
            });

            if (!response.ok) {
                const errorData = await response.text();
                console.error('Error response from server:', errorData);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const session = await response.json();
            console.log('Received session from server:', session);

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error('Stripe redirect error:', result.error.message);
                setError(result.error.message);
            }
        } catch (error) {
            console.error('Error in initiateCheckout:', error);
            setError(error.message);
        }
    };

    return (
        <div className={styles.stripeCheckoutContainer}>
            <h2 className={styles.title}>Redirecting to Stripe Checkout...</h2>
            <p className={styles.instruction}>If you're not redirected automatically, please click the button below:</p>
            <button 
                onClick={initiateCheckout} 
                disabled={!user}
                className={styles.checkoutButton}
            >
                Proceed to Payment
            </button>
            {error && <p className={styles.errorMessage}>{error}</p>}
        </div>
    );
};

export default StripeCheckoutButton;